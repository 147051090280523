<template>
  <div id="app">
    <div class="titleBack">
      <div style="width: 100%;height: 100%;position: relative;" v-if="$route.path!=='/'">
        <img src="../src/static/home.png" @click="goRouter(1)" style="position: absolute;width: 89px;height: 43px;bottom: 10px;left: 20px;"/>
        <div class="changeBack" @click="goRouter(2)">
          <img src="../src/static/backImg.png"  style=""/>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
  export default {
    name: 'app',
  data() {
      return {
          
      }
  },
  methods: {
    goRouter(value){
      if(value ==1){
        this.$router.push({name:'home'})
      }else{
        this.$router.back()
      }
    }
  },
  mounted() {},
  created() {},
  beforeUpdate() {},
  beforeMount() {}
}
</script>
<style lang="scss">
* {
  box-sizing: border-box; /* 将所有元素的盒模型设置为border-box，避免padding和border影响元素的实际尺寸 */
  margin: 0;
  padding: 0;

}
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
}
.changeBack{
  background: url("../src/static/back.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: 89px;
  height: 43px;
  bottom: 10px;
  right: 20px;
  text-align: center;
  padding-top: 7px;
  box-sizing: border-box;
}
#app {
  background: url("../src/static/backGround.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  height: 100vh; /* 使用视口高度设置元素的高度 */
  overflow: hidden; /* 隐藏溢出内容 */
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.titleBack {
  background: url("../src/static/titleBack.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  // height: 119px;
  height: 100px;
}

/*滚动条整体样式*/
::-webkit-scrollbar {
  width: 5px;
  height: 1px;
}

/*滚动条滑块*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #094b82;
}

/*滚动条轨道*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
  border-radius: 10px;
  background: #142840;
}</style>
