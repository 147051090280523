import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts';
//引用element
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';//使用

Vue.use(Element);
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
import ChartBlock from'../src/components/ChartBlock'
Vue.use(ChartBlock)
import homeData from'../src/utils/home'
Vue.prototype.$homeData = homeData
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
