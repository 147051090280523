<template>
    <div class="homePage">
        <div style="width: 100%;height: auto;display: flex;justify-content: space-between;">
            <div class="homePage1">
                <div class="homePage1_1">
                    <div class="allText" style="margin-top: 43px;">
                        用户数量排行榜
                    </div>
                    <div class="homePage1_11">
                        <div class="homePage1_11b">
                            <div style="font-size: 16px;color: #018FFF;">活跃</div>
                            <div style="font-size: 16px;color: #00FFF0;">{{ distributor.active_num }}</div>
                        </div>
                        <div class="homePage1_11c">
                            <div style="font-size: 16px;color: #018FFF;">预警</div>
                            <div style="font-size: 16px;color: #00FFF0;">{{ distributor.warning_num }}</div>
                        </div>
                    </div>
                    <div style="height: 300px;width: 70%;position: absolute;left: 35px;top: 80px;">
                        <chart-block ref="chart1"></chart-block>
                    </div>
                </div>
                <div class="homePage1_2">
                    <div @click="changeTimeS" style="width: 100%;text-align: right; position: absolute;right: 20px;top: 85px;color: rgba(0, 255, 240, 1);cursor: pointer;z-index: 999;">近7日新增</div>
                    <div style="height: 160px;width: 80%;position: absolute;left: 35px;top: 80px;">
                        <chart-block ref="chart2"></chart-block>
                    </div>
                </div>
            </div>
            <div class="homePage2">
                <div style="width: 100%;height: 150px;display: flex;justify-content: space-around;background: rgb(1 24 41 / 50%);">
                    <div style="width: 300px;height: 100%;">
                        <div class="allText" style="font-size: 24px;position: relative;">分销商数量</div>
                        <div style="width: 100%;display: flex;justify-content: space-around;margin-top: 28px;">
                            <div v-for="(item,index) in homePage2a" :key="index"
                                style="background-color: #002765;  border: 2px solid #01487f; border-radius: 5px;text-align: center;box-sizing: border-box;padding: 10px ;font-size: 36px;color: #fff;width: 40px;">
                                {{ item }}
                            </div>
                        </div>
                    </div>
                    <div
                        style="width: 380px;height: 100%;font-size: 52px;color: rgba(0, 255, 254, 1);padding-top: 20px;text-align: center;">
                        {{ homeTextData.store_name }}
                    </div>
                    <div style="width: 300px;height: 100%;">
                        <div class="allText" style="font-size: 24px;position: relative;">活跃用户数量</div>
                        <div style="width: 100%;display: flex;justify-content: space-around;margin-top: 28px;">
                            <div v-for="(item,index) in homePage2c" :key="index"
                                style="background-color: #002765;  border: 2px solid #01487f;  border-radius: 5px;text-align: center;box-sizing: border-box;padding: 10px ;font-size: 36px;color: #fff;width: 40px;">
                                {{ item }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="homePage2Back">
                    <div class="homePage2_1">
                        <div class="homePage2_text1" style="left: 145px;right: 0;">{{ homeTextData.store_name }}</div>
                        <div class="homePage2_text2" style="right: 145px;">
                            {{  homeTextData.store_mobile.toString().slice(0, 3)+ '****'+ homeTextData.store_mobile.toString().slice(-4)}}
                        </div>
                    </div>
                    <div class="homePage2_2">
                        <div
                            style="position: absolute;width: 110px;height: 110px;top: 10px;left: 10px;border-radius: 50%;background: rgba(20, 32, 113, 1);">
                            <chart-block ref="chart7"></chart-block>
                        </div>
                        <div class="homePage2_text1">本月新增分销商</div>
                        <div class="homePage2_text2">
                            {{ new_distributor_num }}人
                        </div>
                    </div>
                    <div class="homePage2_3">
                        <div
                            style="position: absolute;width: 110px;height: 110px;top: 10px;left: 10px;border-radius: 50%;background: rgba(20, 32, 113, 1);">
                            <chart-block ref="chart8"></chart-block>
                        </div>
                        <div class="homePage2_text1">本月新增用户</div>
                        <div class="homePage2_text2">
                            {{ new_custom_num}}人
                        </div>
                    </div>
                    <div class="homePage2_4">
                        <div
                            style="position: absolute;width: 110px;height: 110px;top: 10px;left: 10px;border-radius: 50%;background: rgba(20, 32, 113, 1);">
                            <chart-block ref="chart9"></chart-block>
                        </div>
                        <div class="homePage2_text1">已激活用户数量</div>
                        <div class="homePage2_text2">
                            {{ active_custom_num }}人
                        </div>
                    </div>
                </div>
            </div>
            <div class="homePage3">
                <div style="margin-top: 5px;" class="allText">卡券数据</div>
                <div class="homePage3_1">
                    <div style="display: flex;width: 100%;">
                        <img src="../../src/static/homePage3_11.png" style="width: 100px;height: 20px;" />
                        <div class="homePage3_12Box">
                            付费卡券
                        </div>
                    </div>
                    <div style="width: 100%;margin-top: 15px;color: #fff;font-size: 14px;">
                        卡券销售额：{{ card.pay_sales_volume }}（元）
                    </div>
                    <div style="height: 200px;width: 80%;position: absolute;left: 35px;top: 80px;">
                        <chart-block ref="chart3"></chart-block>
                    </div>
                    <div style="position: absolute;bottom: 54px;width: 310px;">
                        <div style="display: flex;justify-content: space-between;width: 95%;height: 100%;flex-wrap: wrap;margin: auto;">
                            <div
                                style="width: 142px;height: 53px;background-color: rgba(1, 143, 255, 0.30);box-sizing: border-box;padding: 5px 10px 5px;">
                                <div style="color: #fff;font-size: 12px;">免费卡券：已发放</div>
                                <div style="color: rgba(0, 255, 255, 1);font-size: 16px;margin-top: 6px;">
                                    {{ card.send_free }}
                                </div>
                            </div>
                            <div
                                style="width: 142px;height: 53px;background-color: rgba(1, 143, 255, 0.30);box-sizing: border-box;padding: 5px 10px 5px;">
                                <div style="color: #fff;font-size: 12px;">免费卡券：已核销</div>
                                <div style="color: rgba(0, 255, 255, 1);font-size: 16px;margin-top: 6px;">
                                    {{ card.active_free }}
                                </div>
                            </div>
                            <div
                                style="width: 142px;height: 53px;background-color: rgba(1, 143, 255, 0.30);box-sizing: border-box;padding: 5px 10px 5px;margin-top: 10px;">
                                <div style="color: #fff;font-size: 12px;">付费卡券：已发放</div>
                                <div style="color: rgba(0, 255, 255, 1);font-size: 16px;margin-top: 6px;">
                                    {{ card.send_pay }}
                                </div>
                            </div>
                            <div
                                style="width: 142px;height: 53px;background-color: rgba(1, 143, 255, 0.30);box-sizing: border-box;padding: 5px 10px 5px;margin-top: 10px;">
                                <div style="color: #fff;font-size: 12px;">付费卡券：已核销</div>
                                <div style="color: rgba(0, 255, 255, 1);font-size: 16px;margin-top: 6px;">
                                    {{ card.active_pay }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div
            style="width: 100%;height: auto;display: flex;justify-content: space-between;margin-top: 20px;position: relative;">
            <div class="homePage4">
                <div style="width: 856px;height: 30px;margin: auto;display: flex;justify-content: flex-end;">
                    <div style="color: rgba(255, 199, 0, 1);font-size: 14px;">
                        新增用户
                        <span
                            style="display: inline-block;width: 10px;height: 10px;background: rgba(255, 199, 0, 1);border-radius: 50%;"></span>
                    </div>
                    <div style="color: rgba(1, 143, 255, 1);font-size: 14px;margin-left: 30px;">
                        新增激活
                        <span
                            style="display: inline-block;width: 10px;height: 10px;background: rgba(1, 143, 255, 1);border-radius: 50%;"></span>
                    </div>
                    <div style="z-index: 99;" @click ="changeTime(30)" >
                        <img src="../static/homePage4_1.png" style="width: 50px;height: 20px;margin-left: 80px;" :style="{'background':custom_data_range==30? '#a6e0f4':''}"/>
                    </div>
                    <div style="z-index: 99;"   @click ="changeTime(7)">
                        <img src="../static/homePage4_2.png" style="width: 50px;height: 20px;margin-left: 30px;" :style="{'background':custom_data_range==7? '#a6e0f4':''}"/>
                    </div>
                    <div  style="height: 280px;width: 856px;position: absolute;left: 35px;top: 80px;">
                        <chart-block ref="chart4"></chart-block>
                    </div>
                </div>
            </div>
            <div class="homePage5">
                <div
                    style="position: absolute;left: 33px;top: 52px;height: 72px;display: flex;justify-content: space-between;">
                    <div style="display: flex;justify-content: space-between;width: 216px;color: #fff;">
                        <img src="../static/homePage5_1.png" style="width: 62px;height: 72px;" />
                        <div style="width: 124px;height: 72px;text-align: left;margin-left: 30px;line-height: 2;">
                            <div style="font-size: 14px;">总数量</div>
                            <div style="font-size: 26px;">{{ goods.total }}</div>
                        </div>
                    </div>
                    <div style="display: flex;justify-content: space-between;width: 216px;color: #fff;">
                        <img src="../static/homePage5_1.png" style="width: 62px;height: 72px;" />
                        <div style="width: 124px;height: 72px;text-align: left;margin-left: 30px;line-height: 2;">
                            <div style="font-size: 14px;">总浏览量</div>
                            <div style="font-size: 26px;">{{ goods.views }}</div>
                        </div>
                    </div>
                </div>
                <div style="position: absolute;right: 35px;top: 64px;">
                    <img src="../static/homePage5_3.png" style="width: 105px;height: 20px;" />
                </div>
                <div style="height: 280px;width: 856px;position: absolute;left: 35px;top: 80px;">
                    <chart-block ref="chart5"></chart-block>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { dealer_summary, dealer_chart } from '@/api/allData'
// 导入组件
export default {
    name: 'homeData',
    data() {
        return {
            homePage2a: "008219",
            homePage2c: "001234",
            new_dealer_num: "",
            new_distributor_num: "",
            new_custom_num: "",
            active_custom_num: "",
            homeTextData: "",
            option6Data: {},
            option7Data: {},
            option8Data: {},
            option9Data: {},
            itemId: "",
            dealer: {},
            distributor: {},
            card: {},
            custom: {},
            goods: {},
            custom_data_range:30
        }
    },
    // 注册组件
    components: {
    },
    mounted() {
        if (this.timer) {
            clearInterval(this.timer);
        } else {
            this.timer = setInterval(() => {
                this.getAllTextData()
            }, 5000);//每隔十秒刷新一次
        }
        if (this.timer2) {
            clearInterval(this.timer2);
        } else {
            this.timer2 = setInterval(() => {
                this.getFourData()
                this.getAllechatsData()
            }, 30000);//每隔十秒刷新一次
        }
    },
    destroyed() {//页面关闭时调用
        clearInterval(this.timer)
        clearInterval(this.timer2)
    },
    methods: {
        getTextZhuan(number){
            var reg = /(\d)(?=(?:\d{3})+$)/g;
            var newNumber = number.toString().replace(reg,'$1,');
            return newNumber;
        },
        changeTime(value){
            this.custom_data_range = value
            let parmes = {
                id: this.itemId,
                custom_data_range:this.custom_data_range
            }
            dealer_chart(parmes).then(res => {
                this.custom = res.data.custom
                let option4 = this.$homeData.option4
                option4.xAxis.data = this.custom.map(item => {
                    return item.date
                })
                option4.series = [
                    {
                        name: '新增用户',
                        type: 'line',
                        stack: 'Total',
                        data: this.custom.map(item => {
                            return item.new_custom
                        })
                    },
                    {
                        name: '新增激活',
                        type: 'line',
                        stack: 'Total',
                        data: this.custom.map(item => {
                            return item.new_active
                        })
                    },
                ]
                this.$refs.chart4.setOption(option4)
            })
        },
        changeTimeS(){
            let parmes = {
                id: this.itemId,
                custom_data_range:7
            }
            dealer_chart(parmes).then(res => {
                let option2 = this.$homeData.option2
                option2.xAxis.data = res.data.distributor.week_new.map(item => {
                    return item.date
                })
                option2.series[0].data = res.data.distributor.week_new.map(item => {
                    return item.num
                })
            this.$refs.chart2.setOption(option2)
            })
        },
        getAllechatsData() {
            let parmes = {
                id: this.itemId,
                custom_data_range:this.custom_data_range
            }
            dealer_chart(parmes).then(res => {
                this.distributor = res.data.distributor
                this.card = res.data.card
                this.custom = res.data.custom
                this.goods = res.data.goods
                this.goods.total = this.getTextZhuan(res.data.goods.total)
                this.goods.views = this.getTextZhuan(res.data.goods.views)
                this.getecharts()
            })
        },
        getecharts() {
            let option1 = this.$homeData.option11
            let ranking_list1 = this.distributor.ranking_list.map(item => {
                return item.sub_num;
            })
            let ranking_list2 = this.distributor.ranking_list.map(item => {
                return {
                    name: item.name,
                    value: item.sub_num
                }
            })
            option1.yAxis.data = ranking_list1.reverse()
            option1.series[0].data = ranking_list2.reverse()
            this.$refs.chart1.setOption(option1)
            let option2 = this.$homeData.option2
            option2.xAxis.data = this.distributor.week_new.map(item => {
                return item.date
            })
            option2.series[0].data = this.distributor.week_new.map(item => {
                return item.num
            })
            this.$refs.chart2.setOption(option2)

            let option3 = this.$homeData.option3
            option3.series[0].data = [
                { name: "已发放", value: this.card.send_pay, label: "付费卡券：已发放" },
                { name: "已核销", value: this.card.active_pay, label: "付费卡券：已核销" },
                { name: "已发放", value: this.card.send_free, label: "免费卡券：已发放" },
                { name: "已核销", value: this.card.active_free, label: "免费卡券：已核销" },
            ]
            this.$refs.chart3.setOption(option3)
            let option4 = this.$homeData.option4
            option4.xAxis.data = this.custom.map(item => {
                return item.date
            })
            option4.series = [
                {
                    name: '新增用户',
                    type: 'line',
                    stack: 'Total',
                    data: this.custom.map(item => {
                        return item.new_custom
                    })
                },
                {
                    name: '新增激活',
                    type: 'line',
                    stack: 'Total',
                    data: this.custom.map(item => {
                        return item.new_active
                    })
                },
            ]
            this.$refs.chart4.setOption(option4)
            let option5 = this.$homeData.option5
            option5.xAxis[0].data = this.goods.ranking_list.map(item => {
                return item.name
            })
            option5.series[0].data = this.goods.ranking_list.map(item => {
                return item.views
            })
            option5.series[1].data = this.goods.ranking_list.map(item => {
                return item.views
            })
            option5.series[2].data = this.goods.ranking_list.map(item => {
                return item.views
            })
            this.$refs.chart5.setOption(option5)
        },
        getFourData() {
            this.option7Data = this.$homeData.option7
            this.option7Data.series[0].data[0].value = this.homeTextData == "" ? 0 : parseInt(this.homeTextData.new_distributor_ratio)
            this.option7Data.series[0].data[0].detail.formatter = this.homeTextData == "" ? 0 : this.homeTextData.new_distributor_ratio
            this.option8Data = this.$homeData.option8
            this.option8Data.series[0].data[0].value = this.homeTextData == "" ? 0 : parseInt(this.homeTextData.new_custom_ratio)
            this.option8Data.series[0].data[0].detail.formatter = this.homeTextData == "" ? 0 : this.homeTextData.new_custom_ratio
            this.option9Data = this.$homeData.option9
            this.option9Data.series[0].data[0].value = this.homeTextData == "" ? 0 : parseInt(this.homeTextData.new_active_custom_ratio)
            this.option9Data.series[0].data[0].detail.formatter = this.homeTextData == "" ? 0 : this.homeTextData.new_active_custom_ratio
            this.$refs.chart7.setOption(this.$homeData.option7)
            this.$refs.chart8.setOption(this.$homeData.option8)
            this.$refs.chart9.setOption(this.$homeData.option9)

        },
        getAllTextData() {
            let parmes = {
                id: this.itemId
            }
            dealer_summary(parmes).then(res => {
                this.homeTextData = res.data
                this.homePage2a = res.data.new_distributor_num.toString().padStart(6, '0')
                this.homePage2c = res.data.new_custom_num.toString().padStart(6, '0')
                this.new_distributor_num = this.getTextZhuan(res.data.new_distributor_num)
                this.new_custom_num = this.getTextZhuan(res.data.new_custom_num)
                this.active_custom_num = this.getTextZhuan(res.data.active_custom_num)
                this.getFourData()

            })
        },
    },
    created() {
        this.itemId = this.$route.query.id
        this.$nextTick(()=>{
            this.getAllTextData()
            this.getAllechatsData()
        })
        
    },
    beforeUpdate() { },
    beforeMount() {

    }
}
</script>
<style scoped lang="scss">
.homePage {
    width: 100%;
    height: 100%;

    .allText {
        width: 100%;
        text-align: center;
        font-size: 18px;
        color: rgba(0, 255, 240, 1);
        position: absolute;
    }

    .homePage1 {
        width: 386px;
        height: 570px;
        background: url("../../src/static/homeData1.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .homePage1_1 {
            width: 100%;
            height: 258px;

            position: relative;

            .homePage1_11 {
                width: 62px;
                position: absolute;
                right: 17px;
                top: 41px;

                .homePage1_11a {
                    font-size: 23px;
                    color: rgba(1, 143, 255, 1);
                    font-weight: bold;
                    text-align: center;
                }

                .homePage1_11b {
                    width: 100%;
                    height: 62px;
                    background: url("../../src/static/homePage1_1a.png");
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    margin-top: 14px;
                    text-align: center;
                    line-height: 1.5;
                    padding-top: 10px;
                    box-sizing: border-box;
                }

                .homePage1_11c {
                    text-align: center;
                    line-height: 1.5;
                    width: 54px;
                    margin: auto;
                    margin-top: 14px;
                    background: url("../../src/static/homePage1_1b.png");
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    height: 62px;
                    padding-top: 10px;
                    box-sizing: border-box;
                }
            }
        }

        .homePage1_2 {
            width: 100%;
            height: 258px;
            margin-top: 50px;
            position: relative;

            .homePage1_12 {
                width: 100%;
                display: flex;
                justify-content: space-around;
                top: 47px;
                position: absolute;

                .homePage1_12a {
                    width: 163px;
                    height: 60px;
                    background: url("../../src/static/homePage1_2a.png");
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }

                .homePage1_12b {
                    width: 163px;
                    height: 60px;
                    background: url("../../src/static/homePage1_2b.png");
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
    }

    .homePage2 {
        width: 1100px;
        height: 570px;

        .homePage2_text1 {
            color: #FFFFFF;
            font-size: 20px;
            position: absolute;
            top: 12px;
            right: 72px;
        }

        .homePage2_text2 {
            color: rgba(0, 255, 255, 1);
            font-size: 36px;
            position: absolute;
            bottom: 12px;
            right: 72px;
            width: 220px;
            text-align: right;
        }

        .homePage2Back {
            width: 100%;
            background-color: rgba(1, 62, 107, 0.5);
            padding: 50px 35px 50px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .homePage2_1 {
            width: 423px;
            height: 138px;
            background: url("../../src/static/homePage2_11.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            position: relative;
        }

        .homePage2_2 {
            width: 423px;
            height: 138px;
            background: url("../../src/static/homePage2_2.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            position: relative;
        }

        .homePage2_3 {
            width: 423px;
            height: 138px;
            background: url("../../src/static/homePage2_3.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            margin-top: 41px;
            position: relative;
        }

        .homePage2_4 {
            width: 423px;
            height: 138px;
            background: url("../../src/static/homePage2_4.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            margin-top: 41px;
            position: relative;
        }

    }

    .homePage3 {
        width: 386px;
        height: 570px;
        background: url("../../src/static/homePage3.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .homePage3_1 {
            width: 330px;
            margin: auto;
            margin-top: 63px;
            height: 484px;
            background: url("../../src/static/homePage3_1.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            box-sizing: border-box;
            padding: 10px;
            position: relative;
        }
    }

    .homePage4 {
        width: 940px;
        height: 381px;
        background: url("../../src/static/homePage4.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 84px;
        box-sizing: border-box;
    }

    .homePage5 {
        width: 940px;
        height: 381px;
        background: url("../../src/static/homePage5.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: relative;
    }
    .homePage3_12Box{
        width: 80px;
        height: 20px;
        background: url("../../src/static/homePage3_12.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        text-align:center;
        font-size: 13px;
        box-sizing:border-box;
        padding-top:1px;
        color:#0EFEAC;
    }
}</style>
