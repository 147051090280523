import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'
import homeData from '../views/homeData.vue'
import search from '../views/search.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  }, {
    path: '/homeData',
    name: 'homeData',
    component: homeData
  }, {
    path: '/search',
    name: 'search',
    component: search
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
