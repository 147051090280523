import request from '@/utils/request'
export function admin_summary(params) {
    return request({
        url: '/screen/index/admin_summary',
        method: 'get',
        params,
    })
}
export function admin_chart(params) {
    return request({
        url: '/screen/index/admin_chart',
        method: 'get',
        params,
    })
}
export function search_keyword(params) {
    return request({
        url: '/screen/index/search_keyword',
        method: 'get',
        params,
    })
}
export function search_result(params) {
    return request({
        url: '/screen/index/search_result',
        method: 'get',
        params,
    })
}

export function dealer_summary(params) {
    return request({
        url: '/screen/index/dealer_summary',
        method: 'get',
        params,
    })
}
export function dealer_chart(params) {
    return request({
        url: '/screen/index/dealer_chart',
        method: 'get',
        params,
    })
}