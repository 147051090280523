<template>
  <div class="search">
    <div style="width: 100%;height: 100%;">
      <div class="search1">
        <div class="search1_1">
          <div style="width: 100%;height: 20px;text-align: center;font-size: 16px;color: rgba(0, 255, 240, 1);">
            输入首字母以搜索经销商</div>
          <div class="search1_1box">
            <div class="search1_1box1" v-for="(item, index) in ziMuData" :key="index" @click="changeZisearch(item)">
              <div class="search1_1box1box" :class="ziData == item ? 'search1_1box1boxs' : 'search1_1box1box'">
                {{ item }}
              </div>
            </div>
            <div class="search1_1box2"></div>
          </div>
        </div>
        <div class="search1_2">
          <div style="color: #FFFFFF;height: 300px;overflow-x: hidden;">
            <el-checkbox-group v-model="checkList" max="1" @change="changCheckbox">
              <el-checkbox :label="item.id"  v-for="(item,index) in searchkeywordData.province_list" :key="index">
                {{ item.name }}
              </el-checkbox>
            <div style="width: 314px;margin: auto;height:1px;background-color: #018FFF;margin-top: 20px;margin-bottom: 20px;"></div>
            <el-checkbox :label="item.id"  v-for="(item,index) in searchkeywordData.address_list" :key="index">
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div class="search2">
        <div class="search1_2box" v-for="item in listData" :key="item" @click="goHomeData(item)">
          <div style="width: 80%;white-space: nowrap; overflow: hidden; text-overflow: ellipsis; float: left;">
            {{ item.name }}
          </div>
          <div style="width: 20%;float: right;text-align: right;">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div style="height: 40px;width: 100%;">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { search_keyword, search_result } from '@/api/allData'
// 导入组件
export default {
  name: 'search',
  data() {
    return {
      radio: 1,
      ziMuData: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "#"],
      ziData: "A",
      checkList: [],
      searchkeywordData:[],
      listData:[],
      isId:"1",
    }
  },
  // 注册组件
  components: {
  },
  methods: {
    goHomeData(item){
      this.$router.push({
        path:'/homeData',
        query: {
          id: item.id
        }
      })
    },
    changCheckbox(){
     
      if(this.checkList!==''){
        for(let i in this.searchkeywordData.address_list){
          if(this.searchkeywordData.address_list[i].id == this.checkList[0]){
            this.isId = '3'
          }
        }
        for(let i in this.searchkeywordData.province_list){
          if(this.searchkeywordData.province_list[i].id == this.checkList[0]){
            this.isId = '2'
          }
        }
        this.getListData()
      }
    },
    changeZisearch(item) {
      this.isId = '1'
      this.ziData = item
      this.getListData()
    },
    getFormData() {
      search_keyword().then(res => {
        this.searchkeywordData = res.data
        this.checkList=[]
        this.checkList.push(res.data.province_list.id?res.data.province_list[0].id:res.data.address_list[0].id)
        this.getListData()
      })
    },
    getListData() {
      // 出问题
      let parmes = {
        type: this.isId,
        id:  this.isId=='1'?this.ziData:this.checkList
      }
      search_result(parmes).then(res => {
        this.listData = res.data
      })
    }
  },
  created() {
    this.getFormData()
  },
  beforeUpdate() { },
  beforeMount() {

  }
}
</script>
<style scoped>
.search {
  width: 100%;
  height: 100%;

  .search1 {
    width: 386px;
    height: 100%;
    float: left;

    .search1_1 {
      width: 100%;
      height: 535px;
      background: url("../../src/static/search2.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
      padding-top: 41px;
      box-sizing: border-box;
      clear: both;

      .search1_1box {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        padding: 10px;

        .search1_1box1 {
          width: calc(25% - 10px);
          height: 42px;
          margin-top: 17px;
          color: rgba(0, 255, 240, 1);
          text-align: center;
          font-size: 36px;

          .search1_1box1box {
            width: 54px;
            height: 54px;
            margin: auto;
            cursor: pointer;
            padding-top: 3px;
            box-sizing: border-box;
          }

          .search1_1box1box:hover {
            width: 54px;
            height: 54px;
            background-color: rgba(0, 255, 240, 0.23);
            margin: auto;
            cursor: pointer;
            padding-top: 3px;
            box-sizing: border-box;
          }

          .search1_1box1boxs {
            width: 54px;
            height: 54px;
            background-color: rgba(0, 255, 240, 0.23);
            margin: auto;
            cursor: pointer;
            padding-top: 3px;
            box-sizing: border-box;
          }
        }

        .search1_1box2 {
          width: calc(25% - 10px);
          height: 42px;
          margin-top: 17px;
        }
      }
    }

    .search1_2 {
      width: 100%;
      height: 400px;
      overflow-x: auto;
      background: url("../../src/static/search1.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
      margin-top: 20px;
      padding: 60px 30px;
      font-size: 16px;
      color: #fff;
      box-sizing: border-box;
      overflow: hidden;
      line-height: 3;
    }
  }

  .search2 {
    width: 910px;
    height: 90vh;
    float: left;
    margin-left: 100px;
    overflow: auto;
    box-sizing: border-box;
    padding: 10px 20px 10px;

    .search1_2box {
      width: 100%;
      height: 91px;
      box-sizing: border-box;
      border-bottom: 2px solid #FFFFFF;
      font-size: 24px;
      color: #fff;
      padding: 25px 10px 20px;
    }
  }
}

/* 
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
border-color: #006E60 !important;
background-color: #006E60 !important;
}
.el-checkbox__input.is-checked.el-checkbox__label{
color: #006E60 !important;
}
.el-checkbox__inner:hover{
border-color: #006E60 !important;
}
.el-checkbox__input.is-focus .el-checkbox__inner {
border-color: #006E60 !important;
} */</style>
<style>
.el-checkbox__label{
  color: #FFFFFF;
  font-size: 16px;
}
.el-checkbox__inner{
  background-color: transparent !important;
}
.el-checkbox{
  margin-right: 10px !important;
}
.el-checkbox__input.is-checked+.el-checkbox__label{
  color: #00FFF0 !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
  background-color: #00FFF0 !important;
    border-color: #00FFF0 !important;
}
</style>
  