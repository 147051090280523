import * as echarts from 'echarts';

const option1 = {
  title: {
    text: ' ',
    show: false
  },
  tooltip: {
    trigger: 'axis',
    formatter: "{b}:{c}",
    axisPointer: {
      type: 'shadow'
    }
  },
  grid: {
    top: 5,
    bottom: 5,
    left:"18%",
    right:"60%"
  },
  xAxis: {
    show: false,
    type: "value",
    position: 'top',
    axisTick: { show: false },
    splitLine: { show: false },
    
    axisLabel: {
      color: "#666666", // x 轴刻度标识颜色为 #666666
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: "#ffffff",
      },
    },
  },

  yAxis: {
    data: ['100', '100','100',],
    name: '分销商',
    nameGap: 30, // 距离y轴20px
    nameLocation: "end", // 将名称放在轴线末尾
    nameTextStyle: {
      color: "#fff", // 颜色设置为 #666666
      align: "right",  // 设置名称的对齐方式为左对齐
    },
    type: 'category',
    axisTick: { show: false },
    axisLine: {
      lineStyle: {
        color: "#ffffff", // y 轴轴线颜色为 #D6D6D6
      },
    },
    axisLabel: {
      color: "#ffffff", // y 轴刻度标识颜色为 #666666
    },
  },
  series: [
    {
      type: 'bar',
      barWidth: 10, // 柱子的宽度为20像素
      data: [
        { name: "xxxx经销商", value: 10 },
        { name: "xxxx经销商", value: 10 },
        { name: "xxxx经销商", value: 10 },
        { name: "xxxx经销商", value: 10 },
        { name: "xxxx经销商", value: 10 },
      ],
      itemStyle: {
        color: 'rgba(14, 254, 172, 1)',
      },
      label: {
        show: true, // 显示标签
        position: "right", // 标签显示位置：柱子右边
        formatter: function (params) {
          const xData = params.data.name; // 获取柱子对应的 x 轴的数据
          return `{style|${xData}}`; // 返回 x 轴的总数，并使用自定义的样式标记
        },
        rich: {
          // 定义富文本样式
          style: {
            color: "#ffffff", // 字体颜色为 #666666
            padding: [0, 0, 0, 15], // 距离柱形的右边距离为 10px
          },
        },
      },
    }
  ]
};
const option11 = {
  title: {
    text: ' ',
    show: false
  },
  dataZoom: [
    {
        type: "slider",
        realtime: true, // 拖动时，是否实时更新系列的视图
        startValue: 0,
        endValue: 5,
        width: 5,
        height: "90%",
        top: "5%",
        right: 0,
        // orient: 'vertical', // 设置横向还是纵向, 但是官方不太建议如此使用，建议使用 yAxisIndex 具体指明
        yAxisIndex: [0, 1], // 控制y轴滚动对象
        fillerColor: "#0093ff", // 滚动条颜色
        borderColor: "rgba(17, 100, 210, 0.12)",
        backgroundColor: "#cfcfcf", //两边未选中的滑动条区域的颜色
        handleSize: 0, // 两边手柄尺寸
        showDataShadow: false, //是否显示数据阴影 默认auto
        showDetail: false, // 拖拽时是否展示滚动条两侧的文字
        zoomLock: true,
        moveHandleStyle: {
            opacity: 0,
        },
    },
    {
        type: "inside",
        // width: 0,
        startValue: 0,
        endValue: 10,
        minValueSpan: 10,
        yAxisIndex: [0],
        zoomOnMouseWheel: false, // 关闭滚轮缩放
        moveOnMouseWheel: true, // 开启滚轮平移
        moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
    },
  ],
  tooltip: {
    trigger: 'axis',
    formatter: "{b}:{c}",
    axisPointer: {
      type: 'shadow'
    }
  },
  grid: {
    top: 5,
    bottom: 5,
    left:"18%",
    right:"60%"
  },
  xAxis: {
    show: false,
    type: "value",
    position: 'top',
    axisTick: { show: false },
    splitLine: { show: false },
    
    axisLabel: {
      color: "#666666", // x 轴刻度标识颜色为 #666666
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: "#ffffff",
      },
    },
  },

  yAxis: {
    data: ['100', '100','100',],
    name: '分销商',
    nameGap: 30, // 距离y轴20px
    nameLocation: "end", // 将名称放在轴线末尾
    nameTextStyle: {
      color: "#fff", // 颜色设置为 #666666
      align: "right",  // 设置名称的对齐方式为左对齐
    },
    type: 'category',
    axisTick: { show: false },
    axisLine: {
      lineStyle: {
        color: "#ffffff", // y 轴轴线颜色为 #D6D6D6
      },
    },
    axisLabel: {
      color: "#ffffff", // y 轴刻度标识颜色为 #666666
    },
  },
  series: [
    {
      type: 'bar',
      barWidth: 10, // 柱子的宽度为20像素
      data: [
        { name: "xxxx经销商", value: 10 },
        { name: "xxxx经销商", value: 10 },
        { name: "xxxx经销商", value: 10 },
        { name: "xxxx经销商", value: 10 },
        { name: "xxxx经销商", value: 10 },
      ],
      itemStyle: {
        color: 'rgba(14, 254, 172, 1)',
      },
      label: {
        show: true, // 显示标签
        position: "right", // 标签显示位置：柱子右边
        formatter: function (params) {
          const xData = params.data.name; // 获取柱子对应的 x 轴的数据
          return `{style|${xData}}`; // 返回 x 轴的总数，并使用自定义的样式标记
        },
        rich: {
          // 定义富文本样式
          style: {
            color: "#ffffff", // 字体颜色为 #666666
            padding: [0, 0, 0, 15], // 距离柱形的右边距离为 10px
          },
        },
      },
    }
  ]
};
const option2 = {
  tooltip: {
    trigger: 'axis',
    formatter: "{b}:{c}",
    axisPointer: {
      type: ''
    }
  },
  grid: {
    top: 50,
    bottom: 20,
    left: 10,
  },
  textStyle:{
    color:"#FFFFFF"
  },
  color: 'rgba(1, 143, 255, 1)',
  xAxis: {
    axisTick: {
      show: true // 不显示坐标轴刻度线
    },
    type: 'category',
    data: ['10.18', '10.18', '10.18', '10.18', '10.18', '10.18', '10.18']
  },
  yAxis: {
    splitNumber: 5,
    axisTick: {
      show: false // 不显示坐标轴刻度线
    },
    position: 'right', 
    axisLabel: {
      show: true, // 不显示坐标轴上的文字
    },
    axisLine: {
      show: false, // 不显示坐标轴线
    },
    splitLine: { //多条横线
      show: true,
      lineStyle: {
        type: 'dotted'
      }
    },

    type: 'value'
  },
  series: [{
    data: [10, 100, 200, 300, 400, 500, 600],
    type: 'line'
  },

  ]
}
const option3 = {
  title: {
    text: ' ',
    show: false
  },

  tooltip: {
    trigger: 'axis',
    formatter: "{b}:{c}",
    axisPointer: {
      type: 'shadow'
    }
  },
  grid: {
    top: 5,
    left: "20%",
    bottom: 5
  },
  xAxis: {
    show: false,
    type: "value",
    position: 'top',
    axisTick: { show: false },
    splitLine: { show: false },
    axisLabel: {
      color: "#666666", // x 轴刻度标识颜色为 #666666
      interval: 0,
      formatter: function (value) {
        if (Number.isInteger(value)) {
          return value;
        } else {
          return "";
        }
      },
    },
    axisLine: {
      show: false,
      lineStyle: {
        color: "#ffffff",
      },
    },
  },

  yAxis: {

    name: '分销商',
    nameGap: 30, // 距离y轴20px
    nameLocation: "end", // 将名称放在轴线末尾
    nameTextStyle: {
      color: "#fff", // 颜色设置为 #666666
      align: "right",  // 设置名称的对齐方式为左对齐
    },
    type: 'category',
    axisTick: { show: false },
    data: [
      "已发放",
      "已核销",
      "已发放",
      "已核销",
    ],
    axisLine: {
      lineStyle: {
        color: "rgba(1, 143, 255, 1)", // y 轴轴线颜色为 #D6D6D6
      },
    },
    axisLabel: {
      color: "#ffffff", // y 轴刻度标识颜色为 #666666
    },
  },
  series: [
    {
      type: 'bar',
      barWidth: 10, // 柱子的宽度为20像素
      data: [
        { name: "已发放", value: 10, label: "付费卡券：已发放" },
        { name: "已核销", value: 10, label: "付费卡券：已核销" },
        { name: "已发放", value: 10, label: "免费卡券：已发放" },
        { name: "已核销", value: 10, label: "免费卡券：已核销" },
      ],
      itemStyle: {
        color: (param) => {
          let label = param.data.label
          if (label == '免费卡券：已发放' || label == '免费卡券：已核销') {
            return 'rgba(1, 143, 255, 1)';
          } else {
            return 'rgba(14, 254, 172, 1)';
          }
        }
      }
    }
  ]
};
const option4 = {
  title: {
    text: 'Stacked Line',
    show: false
  },
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    data: ['新增用户', '新增激活'],
    show: false,
  },
  color: ['rgba(255, 199, 0, 1)', 'rgba(1, 143, 255, 1)'],
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    axisLine: {
      show: true, // 不显示坐标轴线
      lineStyle: {
        type: 'solid',
        color: '#ffffff',//左边线的颜色
        width: '1'//坐标线的宽度
      }
    },
    axisTick: {
      show: false // 不显示坐标轴刻度线
    },
    axisLabel: {
      show: true, // 不显示坐标轴上的文字
      textStyle: {
        color: '#fff', //坐标轴字体颜色
      },
    },
    type: 'category',
    boundaryGap: false,
    data: ['10.26', '10.26', '10.26', '10.26', '10.26', '10.26', '10.26']
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      show: true, // 不显示坐标轴上的文字
      textStyle: {
        color: '#fff', //坐标轴字体颜色
      },
    },
    axisLine: {
      show: true, // 不显示坐标轴线
      lineStyle: {
        type: 'solid',
        color: '#ffffff',//左边线的颜色
        width: '1'//坐标线的宽度
      }
    },
    splitLine: { //多条横线
      show: true,
      lineStyle: {
        type: 'dotted'
      }
    },
  },

  series: [
    {
      name: '新增用户',
      type: 'line',
      stack: 'Total',
      data: [120, 132, 101, 134, 90, 230, 210]
    },
    {
      name: '新增激活',
      type: 'line',
      stack: 'Total',
      data: [220, 182, 191, 234, 290, 330, 310]
    },
  ]
};
const option5 = {
  tooltip: {
    trigger: "item"
  },
  grid: {
    left: "10%",
    top: "30%",
    right: "5%",
    bottom: "15%"
  },
  xAxis: [{
    data: ["大专及以下", "本科", "硕士", "博士及以上"],
    axisLabel: {
      textStyle: {
        color: "#fff",
        fontSize: 12
      },
      margin: 20 //刻度标签与轴线之间的距离。
    },

    axisLine: {
      show: true, //不显示x轴
      lineStyle: {
        color: "#ffffff"
      }
    },
    axisTick: {
      show: false //不显示刻度
    },
    splitLine: {
      show: false,
    }
  }],
  yAxis: [{
    splitLine: {
      show: false,
    },
    axisTick: {
      show: false
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: "#ffffff",
      }
    },
    axisLabel: {
      textStyle: {
        color: "#fff",
        fontSize: 12
      }
    },
  }],
  series: [{
    //柱底圆片
    name: "",
    type: "pictorialBar",
    symbolSize: [36, 20], //调整截面形状
    symbolOffset: [0, 10],
    symbol: "diamond",
    z: 12,
    itemStyle: {
      normal: {
        color: "rgba(56, 70, 111, 0.8)"
      }
    },
    data: ["100", "75", "105", "26"]
  },
  //柱体
  {
    name: "",
    type: "bar",
    barWidth: "36",
    showBackground: true,
    backgroundStyle: {
      color: "rgba(56, 70, 111, 1)"
    },
    label: {
      normal: {
        show: true,
        position: "top",
        formatter: e => {
          return e.value;
        },
        fontSize: 14,
        color: "#fff",
        offset: [0, -15]
      }
    },
    itemStyle: {
      normal: {
        color: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          type: "linear",
          global: false,
          colorStops: [{
            //第一节下面
            offset: 0,
            color: "rgba(5, 199, 134, 1)"
          },
          {
            offset: 1,
            color: "rgba(56, 70, 111, 0.8)"
          }
          ]
        }
      }
    },
    data: ["100", "75", "105", "26"]
  },

  //柱顶圆片
  {
    name: "",
    type: "pictorialBar",
    symbolSize: [36, 21], //调整截面形状
    symbolOffset: [0, -10],
    symbol: "diamond",
    z: 12,
    symbolPosition: "end",
    itemStyle: {
      normal: {
        color: "rgba(63, 239, 201, 1)"
      }
    },
    data: ["100", "75", "105", "26"]
  }
  ]
};
var option6 = {
  grid: {
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%"
  },
  
  series: [
    {
      type: 'gauge',
      startAngle: 90,
      endAngle: -270,
      pointer: {
        show: false,
      },
      axisLine: { // 仪表盘轴线相关配置
        show: true, // 是否显示仪表盘轴线
        roundCap: true, // 是否在两端显示成圆形
        lineStyle: { // 仪表盘轴线样式
          width: 10, // 轴线宽度
          color: [
            [20, 'rgba(30, 52, 84, 1)'],
            [40, 'rgba(30, 52, 84, 1)'],
            [60, 'rgba(30, 52, 84, 1)'],
            [80, 'rgba(30, 52, 84, 1)'],
            [100, 'rgba(30, 52, 84, 1)']
          ],
        }
      },
      color: "#000",
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
      },

      splitLine: {
        show: false,
        distance: 0,
        length: 10,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
        distance: 50,
      },
      data: [
        {
          value: 60,
          name: '新增',
          title: {
            offsetCenter: ['0%', '-20%'],
            fontSize: 14,
            color: "#ffffff"
          },
          detail: {
            valueAnimation: true,
            formatter: "60%",
            offsetCenter: ['0%', '20%'],
            fontSize: 20,
            color: "#00FFFF"
          },
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1, [
                { offset: 0, color: '#EC7033' },
                { offset: 1, color: '#F7DB92' }
              ])
            }
          }
        },
      ],


    },
  ],
};
var option7 = {
  grid: {
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%"
  },
  series: [
    {
      type: 'gauge',
      startAngle: 90,
      endAngle: -270,
      pointer: {
        show: false,
      },
      axisLine: { // 仪表盘轴线相关配置
        show: true, // 是否显示仪表盘轴线
        roundCap: true, // 是否在两端显示成圆形
        lineStyle: { // 仪表盘轴线样式
          width: 10, // 轴线宽度
          color: [
            [20, 'rgba(30, 52, 84, 1)'],
            [40, 'rgba(30, 52, 84, 1)'],
            [60, 'rgba(30, 52, 84, 1)'],
            [80, 'rgba(30, 52, 84, 1)'],
            [100, 'rgba(30, 52, 84, 1)']
          ],
        }
      },
      color: "#000",
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
      },

      splitLine: {
        show: false,
        distance: 0,
        length: 10,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
        distance: 50,
      },
      data: [
        {
          value: 60,
          name: '新增',
          title: {
            offsetCenter: ['0%', '-20%'],
            fontSize: 14,
            color: "#ffffff"
          },
          detail: {
            valueAnimation: true,
            formatter: "60%",
            offsetCenter: ['0%', '20%'],
            fontSize: 20,
            color: "#00FFFF"
          },
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1, [
                { offset: 0, color: '#2C63FD' },
                { offset: 1, color: '#789CFF' }
              ])
            }
          }
        },
      ],


    },
  ],
};
var option8 = {
  grid: {
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%"
  },
  series: [
    {
      type: 'gauge',
      startAngle: 90,
      endAngle: -270,
      pointer: {
        show: false,
      },
      axisLine: { // 仪表盘轴线相关配置
        show: true, // 是否显示仪表盘轴线
        roundCap: true, // 是否在两端显示成圆形
        lineStyle: { // 仪表盘轴线样式
          width: 10, // 轴线宽度
          color: [
            [20, 'rgba(30, 52, 84, 1)'],
            [40, 'rgba(30, 52, 84, 1)'],
            [60, 'rgba(30, 52, 84, 1)'],
            [80, 'rgba(30, 52, 84, 1)'],
            [100, 'rgba(30, 52, 84, 1)']
          ],
        }
      },
      color: "#000",
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
      },

      splitLine: {
        show: false,
        distance: 0,
        length: 10,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
        distance: 50,
      },
      data: [
        {
          value: 60,
          name: '新增',
          title: {
            offsetCenter: ['0%', '-20%'],
            fontSize: 14,
            color: "#ffffff"
          },
          axisLine: { // 仪表盘轴线相关配置
            show: true, // 是否显示仪表盘轴线
            roundCap: true, // 是否在两端显示成圆形
            lineStyle: { // 仪表盘轴线样式
              width: 10, // 轴线宽度
              color: [
                [20, 'rgba(30, 52, 84, 1)'],
                [40, 'rgba(30, 52, 84, 1)'],
                [60, 'rgba(30, 52, 84, 1)'],
                [80, 'rgba(30, 52, 84, 1)'],
                [100, 'rgba(30, 52, 84, 1)']
              ],
            }
          },
          detail: {
            valueAnimation: true,
            formatter: "60%",
            offsetCenter: ['0%', '20%'],
            fontSize: 20,
            color: "#00FFFF"
          },
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1, [
                { offset: 0, color: '#8E30FF' },
                { offset: 1, color: '#B67AFF' }
              ])
            }
          }
        },
      ],


    },
  ],
};
var option9 = {
  grid: {
    left: "0%",
    top: "0%",
    right: "0%",
    bottom: "0%"
  },
  series: [
    {
      type: 'gauge',
      startAngle: 90,
      endAngle: -270,
      pointer: {
        show: false,
      },
      color: "#000",
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
      },

      splitLine: {
        show: false,
        distance: 0,
        length: 10,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
        distance: 50,
      },
      data: [
        {
          value: 60,
          name: '新增',
          title: {
            offsetCenter: ['0%', '-20%'],
            fontSize: 14,
            color: "#ffffff"
          },
          detail: {
            valueAnimation: true,
            formatter: "60%",
            offsetCenter: ['0%', '20%'],
            fontSize: 20,
            color: "#00FFFF"
          },
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1, [
                { offset: 0, color: '#28D58A' },
                { offset: 1, color: '#5EFFB9' }
              ])
            }
          }
        },
      ],


    },
  ],
};
export default { option1, option2, option3, option4, option5, option6, option7, option8, option9,option11 };